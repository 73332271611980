
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, Role, UserDto } from '../../../api/types/api';
import AdminButton from '../base/AdminButton.vue';
import AdminSnackbar from '../base/AdminSnackbar.vue';
import StudentDashboardHeadline from './StudentDashboardHeadline.vue';
import StudentsTable from './table/StudentsTable.vue';

@Component({
  components: {
    StudentDashboardHeadline,
    StudentsTable,
    AdminSnackbar,
    AdminButton,
  },
})
export default class StudentDashboard extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  showImportDialog = false;

  get teacher(): UserDto {
    return this.userModule.currentUser;
  }

  get grade(): string {
    return this.teacher.grade!;
  }

  

  get students(): UserDto[] {
    return this.isTherapist || this.isParent
      ? this.schoolModule.patients
      : this.schoolModule.myStudents;
  }

  get isTherapist(): boolean {
    return (
      (this.userModule.currentUser.role === Role.therapist ||
        this.userModule.currentUser.role === Role.admin) &&
      this.schoolModule.clientType === Client.therapy
    );
  }

  get isParent(): boolean {
    return this.userModule.currentUser.role === Role.parent;
  }

  async created(): Promise<void> {
    await this.fetchStudents();
  }

  async fetchStudents(): Promise<void> {
    this.isTherapist || this.isParent
      ? await this.schoolModule.getPatients()
      : await this.schoolModule.getClassStudents(this.teacher.grade);
  }
}
