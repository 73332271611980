
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

@Component
export default class DeleteAllDialog extends Vue {
  @Prop() show!: boolean;
  loading = false;

  @Emit()
  confirmDelete(): void {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
      this.close();
    }, 1000);
    console.log();
  }

  @Emit()
  close(): void {
    console.log();
  }
}
