import { render, staticRenderFns } from "./DeleteAllStudentsButton.vue?vue&type=template&id=28f373df&scoped=true"
import script from "./DeleteAllStudentsButton.vue?vue&type=script&lang=ts"
export * from "./DeleteAllStudentsButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f373df",
  null
  
)

export default component.exports