
import { UserDto } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClassListTemplate from '../pdf-templates/ClassListTemplate.vue';
import DeleteAllDialog from './DeleteAllDialog.vue';

@Component({
  components: { ClassListTemplate, DeleteAllDialog },
})
export default class PrintClassListButton extends Vue {
  @Prop() students!: UserDto[];

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  loading = false;
  dialog = false;

  openDialog(): void {
    this.dialog = true;
  }

  async deleteAll(): Promise<void> {
    try {
      this.loading = true;

      if (this.students.length !== 0) {
        const studentsIds = this.students.map((student) => student._id);
        await this.schoolModule.deleteAllStudentsOfClass({
          schoolId: this.schoolModule.schoolId?.toString() || '',
          grade: this.students?.[0]?.grade || '',
          studentsIds,
        });
        this.deleted();
        this.snackbarModule.showMessage({
          message: this.$i18n.t('snackbar.delete-students.success').toString(),
          isSuccess: true,
        });
      }
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.delete-students.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loading = false;
    }
  }

  @Emit()
  deleted(): void {
    console.log();
  }
}
